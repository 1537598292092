import { FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Row, Col } from 'reactstrap';
import React from 'react';

const AdminPagination = ({ page, pageSize, totalPages, setPagination }: {
  page: number,
  pageSize: number,
  totalPages: number,
  setPagination: Function
}) => {
  const onChangePageSize = (optionSelected: string) => {
    const pageSizeSelected = Number(optionSelected);
    setPagination((prevState: Function) => ({ ...prevState, pageSize: pageSizeSelected }));
  }

  const onChangePage = (buttonSelected: number) => {
    setPagination((prevState: Function) => ({ ...prevState, page: buttonSelected }));
  }

  return (
    <Row className="w-100 mt-5">
      <Col md={6} className="d-flex justify-content-start align-items-center col-sm-12">
        <FormGroup className="d-flex">
          <Label for="el-per-page">
            Elementos por página
          </Label>
          <Input
            type="select"
            id="el-per-page"
            value={pageSize}
            onChange={(e) => onChangePageSize(e.target.value)}
          >
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </Input>
        </FormGroup>
      </Col>
      <Col md={6} className="d-flex justify-content-center align-items-center col-sm-12">
        <Pagination aria-label="Page navigation example" size="lg">
          <PaginationItem
            disabled={page === 1}
          >
            <PaginationLink
              onClick={() => onChangePage(1)}
              first
              href="#"
            />
          </PaginationItem>
          <PaginationItem
            disabled={page === 1}
          >
            <PaginationLink
              onClick={() => onChangePage(page - 1)}
              href="#"
              previous
            />
          </PaginationItem>
          {[...Array(4)].map((arr, index) => {
            let buttonPage = index + 1;
            if (page > 2) buttonPage += page - 2;
            if (buttonPage > totalPages) return;

            return (
              <PaginationItem
                active={page === buttonPage}
                key={index}
              >
                <PaginationLink href="#" onClick={() => onChangePage(buttonPage)}>
                  {buttonPage}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem
            disabled={page === totalPages}
          >
            <PaginationLink
              onClick={() => onChangePage(page + 1)}
              href="#"
              next
            />
          </PaginationItem>
          <PaginationItem
            disabled={page === totalPages}
          >
            <PaginationLink
              onClick={() => onChangePage(totalPages)}
              href="#"
              last
            />
          </PaginationItem>
        </Pagination>
      </Col>
    </Row>
  );
}

export default AdminPagination;
