import { Button, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Media } from 'reactstrap';
import { DecryptJwt } from '../../../utils/decrypt.jwt';
import React, { useState, useEffect } from 'react';
import { navigate, Link } from 'gatsby';

import './index.scss';

const AuthContext = ({ children }: any) => {
  const [isLogged, setIsLogged] = useState(false);
  const [firstLetterOnName, setFirstLetterOnName] = useState('');
  const [isLoggedMenuOpen, setIsLoggedMenuOpen] = useState(false);

  useEffect(() => {
    const userJwt = localStorage.getItem("Authorization");
    if (!userJwt) {
      navigate("/admin/login");
      return;
    }

    const jwtSplit = userJwt.split('.');
    const jwtPayloadDecrypted = DecryptJwt(jwtSplit[1] && jwtSplit[1]);
    if (!jwtPayloadDecrypted) {
      logout();
      return;
    }

    const now = new Date();
    const nbf = new Date(jwtPayloadDecrypted?.nbf * 1000) || undefined;
    const exp = new Date(jwtPayloadDecrypted?.exp * 1000) || undefined;
    if ((nbf === undefined || nbf.getTime() > now.getTime())
    ||  (exp === undefined || exp.getTime() < now.getTime())) {
      logout();
      return;
    }

    setIsLogged(true);
    setFirstLetterOnName(jwtPayloadDecrypted && jwtPayloadDecrypted.firstName[0]);
  }, []);

  const toggleLoggedMenu = () => {
    setIsLoggedMenuOpen((prevState) => !prevState);
  }

  const logout = () => {
    localStorage.removeItem("Authorization");
    navigate("/admin/login");
  }

  return (
    <>{isLogged
      && <>
          <div
            className="collapse-menu"
          >
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                color="primary"
                className="collapse-menu-button"
                onClick={() => toggleLoggedMenu()}
              >
                {firstLetterOnName}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <span className="text-overflow m-0">Bienvenido!</span>
                </DropdownItem>
                {/*
                <Nav>
                  <NavItem>
                    <Link
                      to="/admin/configuracion-de-perfil"
                      className="nav-link"
                    >
                      <i className="bi bi-gear-fill"></i>
                      <span className="sidenav-normal">Configuración de perfil</span>
                    </Link>
                  </NavItem>
                </Nav>
                */}
                <hr className="mt-2 mb-2"/>
                <Nav>
                  <NavItem>
                    <a
                      className="nav-link"
                      onClick={() => logout()}
                    >
                      <i className="bi bi-door-open-fill"></i>
                      <span className="sidenav-normal">Cerrar sesión</span>
                    </a>
                  </NavItem>
                </Nav>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {children}
          <div className="admin-footer"><b>© LaBotana</b> 1999-{new Date().getFullYear()}</div>
        </>
      }</>
  );
}

export default AuthContext;
