import { api } from './api';

export const getNewsCategoriesPaginated = (page: number, pageSize: number, searchText: string) => {
  return api.get(`/v1/news-categories/paginated?page=${page}&pageSize=${pageSize}&searchText=${searchText}`);
}

export const getAllNewsCategories = () => {
  return api.get(`/v1/news-categories/all`);
}

export const getNewsCategoryById = (newsCategoryId: string) => {
  return api.get(`/v1/news-categories/${newsCategoryId}`);
}

export const createNewsCategoryById = (id: string, name: string) => {
  return api.post(`/v1/news-categories`, { id, name });
}

export const updateNewsCategoryById = (id: string, name: string) => {
  return api.put(`/v1/news-categories`, { id, name });
}

export const deleteNewsCategory = (newsId: string) => {
  return api.delete(`/v1/news-categories/${newsId}`);
}
