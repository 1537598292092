import Logo from '../../../../assets/img/common/logo.png';
import MiniLogo from '../../../../assets/img/common/mini-logo.png';
import { Row, Nav, NavItem } from 'reactstrap';
import { Link } from 'gatsby';
import React from 'react';

import './index.scss';

const SideNav = ({ activeMenu }: { activeMenu: string }) => {
  return (
    <Row className="admin-sidenav d-flex align-items-start justify-content-start flex-column">
      <div className="sidenav-header d-flex align-items-start justify-content-center">
        <Link
          to="/admin"
        >
          <img
            alt="Labotana"
            className="navbar-brand-img"
            src={Logo}
          />
          <img
            alt="Labotana"
            className="navbar-brand-img-mobile"
            src={MiniLogo}
          />
        </Link>
      </div>
      <div className="sidenav-body d-flex flex-column">
        <Nav>
          <NavItem>
            <Link
              to="/admin"
              className={`nav-link ${activeMenu === 'dashboard' ? 'active' : ''}`}
            >
              <i className="bi bi-bar-chart-fill"></i>
              <span className="sidenav-normal">Estadísticas</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/admin/noticias"
              className={`nav-link ${activeMenu === 'noticias' ? 'active' : ''}`}
            >
              <i className="bi bi-eyeglasses"></i>
              <span className="sidenav-normal">Noticias</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/admin/categorias-de-noticias"
              className={`nav-link ${activeMenu === 'categorias-de-noticias' ? 'active' : ''}`}
            >
              <i className="bi bi-tags-fill"></i>
              <span className="sidenav-normal">Categorias de noticias</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/admin/tags-de-noticias"
              className={`nav-link ${activeMenu === 'tags-de-noticias' ? 'active' : ''}`}
            >
              <i className="bi bi-tags-fill"></i>
              <span className="sidenav-normal">Tags de noticias</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/admin/usuarios"
              className={`nav-link ${activeMenu === 'usuarios' ? 'active' : ''}`}
            >
              <i className="bi bi-people-fill"></i>
              <span className="sidenav-normal">Usuarios</span>
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/admin/ajustes"
              className={`nav-link ${activeMenu === 'ajustes' ? 'active' : ''}`}
            >
              <i className="bi bi-gear-fill"></i>
              <span className="sidenav-normal">Ajustes</span>
            </Link>
          </NavItem>
        </Nav>
      </div>
      <div className="sidenav-footer"></div>
    </Row>
  );
}

export default SideNav;
