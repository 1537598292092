import { Table, Spinner, Button } from 'reactstrap';
import React from 'react';

const AdminTableData = ({ loading, data, columnNames, columnValues, onEdit, onDelete }: {
  loading: boolean,
  data: any[],
  columnNames: string[],
  columnValues: string[],
  onEdit: Function,
  onDelete: Function,
}) => {
  return (
    <Table hover className="mt-4">
      <thead>
        <tr>
          {columnNames && columnNames.map((name: string, index: number) => (
            <th
              key={index}
            >
              {name}
            </th>
          ))}
          <th>
            Acciones
          </th>
        </tr>
      </thead>
      <tbody>
        {loading
          && <tr>
            <td></td>
            <td></td>
            <td className="d-flex justify-content-center">
              <Spinner
                color="primary"
                style={{
                  height: '3rem',
                  width: '3rem'
                }}
              >
                Loading...
              </Spinner>
            </td>
            <td></td>
            <td></td>
          </tr>
        }
        {data && data.map((dataValue: any, dataIndex: number) => (
          <tr
            key={dataIndex}
          >
            {columnValues && columnValues.map((column: any, columnIndex: number) => (
              <td
                key={columnIndex}
              >
                {dataValue[column]}
              </td>
            ))}
            <td>
              <Button
                  onClick={() => onEdit(dataValue.id)}
                  className="btn btn-secondary"
                >
                  Editar
                </Button>
                {" "}
                <Button
                  onClick={() => onDelete(dataValue.id)}
                  color="danger"
                >
                  Eliminar
                </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default AdminTableData;
